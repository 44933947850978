import gql from 'graphql-tag'

export const Dashboard = gql`
  query dashboard {
    dashboard {
      sendcargoDispatched
      sendcargoAmount
      completedOrders
      completedOrdersAmount
      commission
    }
  } 
`
