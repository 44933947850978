<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser && currentUser.owner"
    >
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-title
        class="justify-center"
        >
        Comisiones
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div
          class="text-center"
          >
          <div
            class="text-subtitle-1 primary--text font-weight-medium"
            >
            Comisión por defecto
          </div>
          <div
            class="mx-auto mt-3"
            style="max-width: 300px"
            >
            <commission
              v-if="globalCommission"
              :commission="globalCommission"
              @reload="fetchGlobalCommission"
              ></commission>
          </div>
        </div>

        <v-divider
          class="my-3"
          ></v-divider>

        <groups></groups>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import { GlobalCommission } from '@/graphql/queries/commissions'

const Commission = () => import('@/components/partners_fans/commissions/Detail')
const Groups = () => import('@/components/partners_fans/commissions/Groups')

export default {
  data: () => ({
    globalCommission: null
  }),

  watch: {
    currentUser (val) {
      if (!val.owner) {
        this.$router.push({ name: 'home' })
      }
    }
  },

  created () {
    if (this.currentUser && !this.currentUser.owner) {
      this.$router.push({ name: 'home' })
    }

    this.fetchGlobalCommission ()
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    fetchGlobalCommission () {
      this.$apollo.query({
        query: GlobalCommission,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.globalCommission = res.data.globalCommission
      })
    }
  },

  components: { Commission, Groups }
}
</script>
