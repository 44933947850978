<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser && currentUser.owner"
    >
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-title
        class="justify-center"
        >
        Órdenes
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text
        class="text-center"
        >
        <list
          outlined
          flat
          root
          ></list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import List from '@/components/orders/List'

import {mapGetters} from 'vuex'

export default {
  watch: {
    currentUser (val) {
      if (!val.owner) {
        this.$router.push({ name: 'home' })
      }
    }
  },

  created () {
    if (this.currentUser && !this.currentUser.owner) {
      this.$router.push({ name: 'home' })
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: {
    List
  }
}
</script>
