import gql from 'graphql-tag'

export const GlobalCommission = gql`
  query globalCommission {
    globalCommission {
      id
      amount
    }
  } 
`
