<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser && currentUser.owner"
    >
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-title
        class="justify-center"
        >
        Panel de control
      </v-card-title>

      <v-card-subtitle
        class="text-center"
        >
        Últimos 30 días
      </v-card-subtitle>

      <v-divider></v-divider>

      <v-card-text
        v-if="result"
        >
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="6"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-text
                class="text--primary"
                >
                <div
                  class="text-subtitle-1 font-weight-medium"
                  >
                  Órdenes completadas
                </div>

                <div
                  class="body-1"
                  >
                  {{ result.completedOrders }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="6"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-text
                class="text--primary"
                >
                <div
                  class="text-subtitle-1 font-weight-medium"
                  >
                  Monto de órdenes completadas
                </div>

                <div
                  class="body-1"
                  >
                  {{ formatNumber(result.completedOrdersAmount) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="6"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-text
                class="text--primary"
                >
                <div
                  class="text-subtitle-1 font-weight-medium"
                  >
                  Envíos efectuados (Sendcargo)
                </div>

                <div
                  class="body-1"
                  >
                  {{ result.sendcargoDispatched }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="6"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-text
                class="text--primary"
                >
                <div
                  class="text-subtitle-1 font-weight-medium"
                  >
                  Monto Sendcargo
                </div>

                <div
                  class="body-1"
                  >
                  {{ formatNumber(result.sendcargoAmount) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="6"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-text
                class="text--primary"
                >
                <div
                  class="text-subtitle-1 font-weight-medium"
                  >
                  Comisión
                </div>

                <div
                  class="body-1"
                  >
                  {{ formatNumber(result.commission) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import { Dashboard } from '@/graphql/queries/dashboard'

export default {
  data: () => ({
    result: null
  }),

  watch: {
    currentUser (val) {
      if (!val.owner) {
        this.$router.push({ name: 'home' })
      }
    }
  },

  created () {
    if (this.currentUser && !this.currentUser.owner) {
      this.$router.push({ name: 'home' })
    }

    this.fetchDashboard ()
  },

  methods: {
    fetchDashboard () {
      this.$apollo.query({
        query: Dashboard
      }).then ( res => {
        this.result = res.data.dashboard
      })
    },

    formatNumber (val) {
      const options = { style: 'currency', currency: 'CLP' };
      const numberFormat = new Intl.NumberFormat('es-CL', options);

      return numberFormat.format(val)
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
